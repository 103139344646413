<template>
  <div class="card">
    <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 9px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Journal </span> - List
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card-body" style="padding: 0px;">

      <div class="table-responsive">
        <table id="voucher-table"
               class="table table-bordered table-columned"
               data-search="false"
               data-pagination="false"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
            <th data-width="100" data-field="doc_no" data-sortable="true" data-align="center">Doc No</th>
            <th data-width="107" data-field="doc_date" data-sortable="true" data-formatter="dateFormatter" >Date</th>
            <th data-field="details" data-formatter="ledgerFormatter" data-sortable="true" >Descrption</th>
            <th data-field="remarks" data-sortable="true">Narration</th>
            <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-move" ></i>Move</a>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <vmodal name="voucher-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
        <JournalForm v-bind:myvoucher="voucher" v-on:journal_updated="closeModal" ></JournalForm>
      </vmodal>

      <vmodal name="ibr-move-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="250" height="300" >
        <!-- Form -->
        <form class="modal-body form-validate" action="#">
          <div class="text-center mb-3">
            <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
            <h5 class="mb-0">Choose Branch to be Move</h5>
          </div>

          <div class="form-group form-group-feedback form-group-feedback-left">
            <select class="form-control" v-model="selected_ibr">
              <option v-for="brn in branches" v-bind:value="brn.code">
                {{ brn.name }}
              </option>
            </select>
            <div class="form-control-feedback">
              <i class="icon-box text-muted"></i>
            </div>
          </div>


          <div class="form-group">
            <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" @click="moveToIBR">Move <i class="icon-move-right ml-2"></i></button>
          </div>

        </form>
        <!-- /form -->

      </vmodal>

    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone'
  //import JournalForm from '@/components/v1/accounts/journal/JournalForm.vue'
  import JournalForm from './JournalForm';
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'JournalView',
    components: {
      JournalForm
    },
    store,
    data () {
      return {
        mytable: {},
        voucher: JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}'),
        selected_ibr : 1,
        voucher_id:'',
        branches: [],
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      self.$data.mytable = $('#voucher-table');

      self.loadBranches();

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      $('#voucher-table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyVoucher(id);
          }else if ($(e.target).text() === 'Cancel') {
            self.cancelVoucher(id);
          }else if ($(e.target).text() === 'Move') {
            self.$data.voucher_id = id;
            self.$modal.show('ibr-move-window');
          }
        }
      });

      $('#txtsearch').keyup(function () {

        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $('#voucher-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();

      });

      self.loadData();

    },
    methods:{
      showModal () {
        this.$modal.show('voucher-window');
      },
      beforeOpen(){

      },
      beforeClose(){

      },
      closeModal () {
        this.$modal.hide('voucher-window');
      },
      moveToIBR(id) {
        const self = this;

        const requestOptions = {
          method: 'PUT',
          mode:"cors",
          headers: userService.authHeader()
        };



        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/move/${self.$data.voucher_id}/?ibr=${self.$data.selected_ibr}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            swal ( { title: "Ok" ,  text: 'Successfully moved', type:  "success"} );
            self.$data.voucher_id = '';
            self.$data.selected_ibr = 0;
            self.$modal.hide('ibr-move-window');
            self.loadData();
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      loadData(){
        let self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let ibr = localStorage.getItem('branch_code');

        self.$data.mytable.bootstrapTable('load',[]);
        self.$data.mytable.bootstrapTable('showLoading');


        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/${store.state.user.finyear}/1008/?ibr=${ibr}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray (resp.data)) {
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });


      },
      modifyVoucher(id){
        let self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.voucher = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = resp.data.doc_date.slice(0,10);


            self.$modal.show('voucher-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });


      },
      cancelVoucher (id) {
        let self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'What is the reason for cancel this voucher?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/${id}/?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('voucher-window');
              if(resp.accepted){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.message, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
      loadBranches(){

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.branches = [];

        //Loads the Branch
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/branch/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            if( _.isArray(resp.data)){
              self.$data.branches =  resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }

        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });
      }
    }
  }

</script>

<style scoped>

</style>
